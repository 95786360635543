'use client';
import { useEffect, useMemo, useCallback, useState, useRef } from 'react';
import { useMessageContext } from '@ui/components/organisms/message';
import { ContentType, ContextUserType } from '@ui/constants/context';
import PostItem from './post-item';
import { CommentList, Separator } from '@ui/components';
import { ReplyRefProvider } from '@ui/contexts';
import { PostItemSkeleton } from './post-item-skeleton';
import { MessageUpdateFormPortal } from '../common/message-update-form-portal';
import { MessageUpdateFormProvider } from '@ui/contexts/message-update-form-context';
import { sendGAEvent } from '@next/third-parties/google';
import { PostItemMainRef } from './post-item-main-ref';
import { INotification } from '@ui/components/organisms/message';

interface Props {
  userType: ContextUserType;
  contentType: ContentType;
  main_post_ref: string;
}

interface NotificationState {
  notification: INotification | undefined;
  hasUnreadNotification: boolean;
}

export const PostView = ({ userType, contentType, main_post_ref }: Props) => {
  const messageState = useMessageContext(userType);

  // Find notification and check if unread
  const { hasUnreadNotification, notification } = useMemo<NotificationState>(() => {
    const notification = messageState.newNotifications?.notifications?.find(
      (n) => n?.miscellaneous?.entityId === messageState.singleMessage?.id,
    );

    if (
      userType !== 'fan' ||
      !messageState.newNotifications?.unread_count ||
      !messageState.singleMessage?.id
    ) {
      return {
        notification: undefined,
        hasUnreadNotification: false,
      };
    }

    return {
      notification,
      hasUnreadNotification: !!notification?.read,
    };
  }, [messageState.newNotifications, messageState.singleMessage?.id, userType]);

  // Mark as read when viewing
  useEffect(() => {
    if (hasUnreadNotification || !notification) return;

    messageState.updateNotification!({
      id: notification.id,
      user_id: messageState?.user_id,
      read: true,
      notificationType: 'post',
      creator_id: messageState?.creator_id,
    }).catch((error) => console.error('Error marking notification as read:', error));
  }, [hasUnreadNotification, notification]);

  useEffect(() => {
    if (userType !== 'creator' && messageState.singleMessage) {
      // Track the post view event for non-creators
      sendGAEvent('event', 'PostViewPage', {
        evet_label: 'Post View',
        postId: main_post_ref,
      });
    }
  }, [userType, messageState.singleMessage]);

  if (messageState.isLoadingSingleMessage) {
    return (
      <div className="mx-auto flex w-full max-w-[756px]">
        <PostItemSkeleton />
      </div>
    );
  }

  if (messageState.isSingleMessageError) {
    return (
      <div className="m-auto max-w-sm">
        <div className="flex flex-col justify-center space-y-4">
          <div className="text-center">
            <p className="font-nunito text-korDarkGrey text-3xl font-extrabold">
              {messageState?.singleMessageError?.status === 403
                ? 'Oops, post is restricted.'
                : 'Something went wrong'}
            </p>
            <p className="font-nunito text-korDarkGrey/40">
              {messageState?.singleMessageError?.data?.message || 'error loading post...'}
            </p>
          </div>
          <Separator className="" />
        </div>
      </div>
    );
  }

  if (!messageState.singleMessage) {
    return <div>No post found</div>;
  }

  return (
    <MessageUpdateFormProvider>
      <>
        <MessageUpdateFormPortal userType={userType} main_post_ref={main_post_ref} />

        <div className="mx-auto flex w-full max-w-[756px] flex-col space-y-4">
          {main_post_ref && messageState.mainPostRef && (
            <PostItemMainRef
              mainPostRef={messageState.mainPostRef}
              userType={userType}
              userId={messageState.user_id}
              communityPostRoute={messageState.communityPostRoute}
              communityCommentRoute={messageState.communityCommentRoute}
              communityReplyRoute={messageState.communityReplyRoute}
              query={messageState.query}
              contentType={contentType}
              fullDiscussionRoute={messageState.singleMessage.parent_post_slug}
            />
          )}
          <PostItem
            key={messageState.singleMessage.id}
            post={messageState.singleMessage}
            userType={userType}
            userId={messageState.user_id}
            creator_id={messageState.singleMessage.creator_id}
            communityPostRoute={messageState.communityPostRoute}
            isOwnPage
            query={messageState.query}
            contentType={contentType}
          />
          {contentType !== 'reply' && (
            <p className="font-nunito text-sm font-semibold">
              {contentType === 'post' ? 'Comments' : 'Replies'}
            </p>
          )}

          {contentType !== 'reply' && (
            <ReplyRefProvider>
              <CommentList
                contentType={contentType}
                userType={userType}
                post={messageState.singleMessage}
                creator_id={messageState.singleMessage.creator_id}
                user_id={messageState.user_id}
                main_post_ref={main_post_ref}
                enableReply={contentType === 'post'}
              />
            </ReplyRefProvider>
          )}
        </div>
      </>
    </MessageUpdateFormProvider>
  );
};
